import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import AnswerInterface from "./components/AnswerInterface";
import { Container, Message, Header } from "semantic-ui-react";
import { startSession, loadSession, SessionContext } from "./hooks/session";
import EnterTan from "./components/EnterTan";
import logo from "./FeuerwehrAgentur_LOGO.png";
import { answersState } from "./hooks/answerStore";
import { RecoilRoot, useSetRecoilState } from "recoil";
import { currentQuestionIdState } from "./components/AnswerInterface";

function Main({ session, setSession }) {
  const params = useParams();
  const [tan, setTan] = useState(params.tan);
  const [error, setError] = useState();
  const [token, setToken] = useState(/* localStorage.getItem('token') */);
  const setAnswerState = useSetRecoilState(answersState);
  const setCurrentQuestionId = useSetRecoilState(currentQuestionIdState);
  useEffect(() => {
    let discard = false;

    if (tan && !token) {
      startSession(tan)
        .then((rs) => {
          if (!discard) {
            localStorage.setItem("token", rs.data.session.token);
            setToken(rs.data.session.token);
            setCurrentQuestionId(
              rs.data.session.event.eventType.questionnaire.questions[0].id
            );
          }
        })
        .catch(setError);
    }
    if (token && !session) {
      loadSession(token)
        .then((rs) => {
          if (!discard) {
            setSession(rs.data.session);
            setCurrentQuestionId(
              rs.data.session.event.eventType.questionnaire.questions[0].id
            );
            setAnswerState((old) =>
              rs.data.session.answers.reduce(
                (acc, cur) => ({ ...acc, [cur.question.id]: cur.value }),
                {}
              )
            );
          }
        })
        .catch(setError);
    }

    return () => (discard = true);
  }, [tan, token, session, setSession]);
  if (session) return <AnswerInterface />;
  if (!tan || !token || (token && !session))
    return (
      <div className="pt-4">
        <p className="mb-2">
          Herzlich willkommen im Evaluationssystem der FeuerwehrAgentur für Aus-
          und Fortbildung in der Feuerwehr.
        </p>
        <p className="mb-2">
          Mit Ihrer Teilnahme unterstützen Sie uns, Potenziale im
          Lehrgangsbetrieb zu erkennen und Stärken ausbauen. Wir danken Ihnen
          daher sehr herzlich, dass Sie sich diese Zeit nehmen.
        </p>
        <p className="mb-2">
          Ihre Angaben sind anonym und nicht zurückverfolgbar.
        </p>
        <p className="mb-4">
          Bitte geben Sie Ihren Zugangscode ein, um mit der Auswertung zu
          beginnen.
        </p>
        <EnterTan onTanEntered={setTan} />
        {error && <Message negative>{error.message}</Message>}
      </div>
    );
  return <Message>Lade...</Message>;
}

export default function App() {
  const [session, setSession] = useState();

  return (
    <RecoilRoot>
      <SessionContext.Provider value={session}>
        <Router>
          <header style={{ marginTop: "1em" }}>
            <Container text>
              <Header as="h1">Evaluationssystem der FeuerwehrAgentur</Header>
            </Container>
          </header>
          <main>
            <Container text>
              <Switch>
                <Route path="/:tan">
                  <Main session={session} setSession={setSession} />
                </Route>
                <Route path="/">
                  <Main session={session} setSession={setSession} />
                </Route>
              </Switch>
            </Container>
          </main>
          <footer style={{ marginTop: "2em", marginBottom: "1em" }}>
            <Container text>
              <p style={{ textAlign: "center", color: "#555" }}>
                &copy; FeuerwehrAgentur {new Date().getFullYear()} -{" "}
                <a
                  href="https://www.feuerwehragentur.de/impressum"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Impressum
                </a>
              </p>
            </Container>
            <Container text>
              <a
                href="https://www.feuerwehragentur.de"
                target="_blank"
                className="block mx-auto w-48 my-8"
                rel="noopener noreferrer"
              >
                <img src={logo} alt="" />
              </a>
            </Container>
          </footer>
          <div className="fwag-bg fixed -z-10 bottom-0 w-full h-screen" />
        </Router>
      </SessionContext.Provider>
    </RecoilRoot>
  );
}
