import { useState, useMemo, useEffect } from "react";
import {
  Button,
  Checkbox,
  Form,
  Icon,
  Input,
  Loader,
  Message,
  Progress,
  Radio,
  Segment,
  Select,
  Table,
  TextArea,
} from "semantic-ui-react";
import styled from "styled-components";
import { useAnswer, useIsValid, useAnswers } from "../hooks/answerStore";
import { useSession } from "../hooks/session";
import { LumdatalInit } from "./LumdatalInit";
import nunjucks from "nunjucks";
import { atom, useRecoilState, useSetRecoilState } from "recoil";

function RadioButton({ question, value, showLabel, answer, setAnswer }) {
  const multiple = question.type.options.multiple === true;

  const checkboxChangeFn = useMemo(() => {
    return (e, data) => {
      const newVal = !data.checked
        ? answer.replaceAll(`%${value}`, "")
        : `${answer ?? ""}%${value}`;
      setAnswer(newVal);
    };
  }, [answer, setAnswer, value]);

  return !multiple ? (
    <Radio
      name={question.id}
      value={value}
      checked={answer === value}
      onChange={() => setAnswer(value)}
      title={value}
      className="py-2"
      label={showLabel ? value : undefined}
    />
  ) : (
    <Checkbox
      name={`${question.id}-${value}`}
      value={value}
      checked={(answer ?? "").includes(value)}
      onChange={checkboxChangeFn}
      title={value}
      className="py-2"
      label={showLabel ? value : undefined}
    />
  );
}
function RadioQuestion({ question }) {
  const type = question.type;
  const { answer, setAnswer, dirty, valid } = useAnswer(question);
  return (
    <Table.Row>
      <Table.Cell>
        <span dangerouslySetInnerHTML={{ __html: question.text }} />
        {question.required === true && (
          <>
            {" "}
            <abbr title="Pflichtfeld">*</abbr>
          </>
        )}
      </Table.Cell>
      {type.options.values.map((value, i) => (
        <Table.Cell
          negative={question.required === true && !valid}
          positive={valid}
          key={i}
        >
          <RadioButton
            title={value}
            key={i}
            question={question}
            value={value}
            answer={answer}
            setAnswer={setAnswer}
          />
        </Table.Cell>
      ))}
    </Table.Row>
  );
}

function Questions({ questions }) {
  if (questions.length === 1) return <SingleQuestion question={questions[0]} />;
  const type = questions[0].type;
  switch (type.style) {
    case "RADIO":
      return (
        <>
          <h2 className="h2 text-xl">
            <span
              dangerouslySetInnerHTML={{ __html: questions[0].startText }}
            />
          </h2>
          <Table definition>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                {type.options.values.map((value, i) => (
                  <Table.HeaderCell key={i}>{value}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {questions.map((question, i) => (
                <RadioQuestion key={i} question={question} />
              ))}
            </Table.Body>
          </Table>
        </>
      );
    default:
      return (
        <Message
          negative
          content={`Dieser Fragetyp funktioniert noch nicht. (${type.style})`}
        />
      );
  }
}
const RadioAnswer = ({ question, answer, setAnswer, persisting }) => {
  return (
    <>
      {question.type.options.values.map((value, i) => (
        <div key={i}>
          <RadioButton
            question={question}
            value={value}
            answer={answer}
            setAnswer={setAnswer}
            showLabel
          />
        </div>
      ))}
      <Loader loading={persisting} />
    </>
  );
};
function Answer({ question, answer, setAnswer, persisting }) {
  const type = question.type;
  switch (type.style) {
    case "DROPDOWN":
      return (
        <Select
          placeholder="Auswählen..."
          value={answer}
          loading={persisting}
          options={type.options.values.map((v) => ({
            key: v,
            value: v,
            text: v,
          }))}
          onChange={(e, { value }) => setAnswer(value)}
        />
      );
    case "RADIO":
      return (
        <RadioAnswer
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          persisting={persisting}
        />
      );
    case "INT":
      return (
        <Input
          type="number"
          value={answer ?? ""}
          loading={persisting}
          placeholder={type.options.placeholder}
          onChange={(e) => setAnswer(e.target.value)}
          min={type.options.min}
          max={type.options.max}
        />
      );
    case "SHORTTEXT":
      return (
        <Input
          type="text"
          value={answer ?? ""}
          loading={persisting}
          placeholder={type.options.placeholder}
          onChange={(e) => setAnswer(e.target.value)}
        />
      );
    case "LONGTEXT":
      return (
        <Form>
          <TextArea
            value={answer ?? ""}
            loading={persisting}
            placeholder={type.options.placeholder}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </Form>
      );
    default:
      return (
        <Message
          negative
          content={`Dieser Fragetyp funktioniert noch nicht. (${type.style})`}
        />
      );
  }
}

function Question({ question }) {
  const { answer, setAnswer, persisting, dirty, valid } = useAnswer(question);
  return (
    <Table.Row>
      <Table.Cell>
        <span dangerouslySetInnerHTML={{ __html: question.text }} />
        {question.required === true && (
          <>
            {" "}
            <abbr title="Pflichtfeld">*</abbr>
          </>
        )}
      </Table.Cell>
      <Table.Cell
        negative={question.required === true && !valid}
        positive={valid}
      >
        <Answer
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          persisting={persisting}
          dirty={dirty}
          valid={valid}
        />
      </Table.Cell>
    </Table.Row>
  );
}

function SingleQuestion({ question }) {
  const { answer, setAnswer, persisting, dirty, valid } = useAnswer(question);
  return (
    <>
      <h2 className="h2 text-xl">
        <span dangerouslySetInnerHTML={{ __html: question.text }} />
      </h2>
      <Segment negative={question.required === true && !valid} positive={valid}>
        <Answer
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          persisting={persisting}
          dirty={dirty}
          valid={valid}
        />
      </Segment>
      {/* <DefaultActions continueDisabled={question.required && !valid} cantGoBack={false} onNext={onNext} onPrevious={onPrevious} /> */}
    </>
  );
}

function DefaultActions({
  cantGoBack,
  continueDisabled,
  onNext: handleNext,
  onPrevious: handlePrevious,
}) {
  return (
    <div>
      {!cantGoBack && (
        <Button
          basic
          floated="left"
          icon
          labelPosition="left"
          onClick={handlePrevious}
        >
          <Icon name="left arrow" />
          Zurück
        </Button>
      )}
      <Button
        positive
        icon
        labelPosition="right"
        disabled={continueDisabled}
        onClick={handleNext}
      >
        Weiter
        <Icon name="right arrow" />
      </Button>
      <p style={{ clear: "both" }} />
    </div>
  );
}

const ActionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
`;

function commonStartText(questions) {
  let start = "";
  const maxLen = questions.map((q) => q.text.length).sort()[0];
  let diff = false;
  do {
    start = questions[0].text.substr(0, start.length + 1);
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].text.indexOf(start) !== 0) {
        diff = true;
        break;
      }
    }
  } while (!diff && start.length < maxLen);
  return start.substring(0, start.length - 1);
}

function checkNextQuestionCondition(condition, answer) {
  const rendered = nunjucks.renderString(condition, { answer });
  const val = /true|1/gi.test(rendered);
  return val;
}

export const currentQuestionIdState = atom({
  key: "currentQuestionId",
});

export default function AnswerInterface() {
  const session = useSession();
  const questionnaire = session.event.eventType.questionnaire;
  const answers = useAnswers();
  const [currentQuestionId, setCurrentQuestionId] = useRecoilState(
    currentQuestionIdState
  );
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  //const [Actions, setActions] = useState(DefaultActions);
  // const steps = useMemo(() => {
  //   const steps = [
  //     ...questionnaire.questionGroups
  //       .map((questionGroup, i) =>
  //         () => <QuestionGroup key={questionGroup.id} index={i} questionGroup={questionGroup} questionnaire={questionnaire} />
  //       ),
  //     Comment
  //   ]
  //   if (accessCode.batch.event.raffle) steps.push(EnterRaffle)
  //   return steps
  // }, [accessCode, questionnaire]);
  useEffect(
    () =>
      setCurrentQuestionId((c) =>
        c === "" ? questionnaire.questions[0].id : c
      ),
    [questionnaire]
  );

  const currentQuestionIdx = questionnaire.questions.findIndex(
    (q) => q.id === currentQuestionId
  );
  const [showEndScreen, setShowEndScreen] = useState(false);
  const { questions, groups, nextQuestion, activeGroupIndex } = useMemo(() => {
    const questions = [];
    let nextQuestionId = null;
    for (let q of questionnaire.questions) {
      if (nextQuestionId) {
        if (q.id === nextQuestionId) nextQuestionId = null;
        else {
          continue;
        }
      }
      if (q.nextCondition) {
        const answer = answers[q.id];
        if (checkNextQuestionCondition(q.nextCondition.condition, answer)) {
          nextQuestionId = q.nextCondition.nextQuestion;
        }
      }
      questions.push(q);
    }

    const groups = questions
      .reduce((acc, cur) => {
        const prevArr = [...(acc[acc.length - 1] ?? [])];
        if (prevArr.length > 0 && prevArr[0].type.id === cur.type.id) {
          prevArr.push(cur);
          const commonStart = commonStartText([...prevArr, cur]);
          if (commonStart.length > 10 && commonStart.endsWith(" "))
            return [...acc.slice(0, acc.length - 1), prevArr];
        }
        return [...acc, [cur]];
      }, [])
      .map((qg) => {
        if (qg.length === 1) return qg;
        const commonStart = commonStartText(qg);
        return qg.map((q) => ({
          ...q,
          text: q.text.substr(commonStart.length),
          startText: commonStart.trim(),
        }));
      });

    const activeGroupIndex = groups.findIndex(
      (group) => group.findIndex((q) => q.id === currentQuestionId) >= 0
    );

    const group = groups[activeGroupIndex];
    const lastGroupQuestionIdx = questions.findIndex(
      (q) => q.id === group[group.length - 1].id
    );
    let nextQuestion = questions[lastGroupQuestionIdx + 1];

    const rs = { questions, groups, activeGroupIndex, nextQuestion };
    return rs;
  }, [questionnaire, answers, currentQuestionId]);

  // const CurrentStep = steps[currentStep];

  const progress = Math.round((100 * currentQuestionIdx) / questions.length);

  const lumdatal = session.event.name.includes("Lumda");

  return (
    <>
      <ActionRow>
        {/* <Button negative onClick={() => {
        setConfirmEndSessionOpen(true)
      }}>Teilnahme beenden</Button> */}
      </ActionRow>
      <h4 className="text-md text-gray-500 mb-2">{session.event.name}</h4>
      {lumdatal && !privacyAccepted ? (
        <LumdatalInit onAccepted={() => setPrivacyAccepted(true)} />
      ) : (
        <>
          {!lumdatal && <Progress percent={progress} autoSuccess size="tiny" />}
          {!showEndScreen ? (
            <Questions questions={groups[activeGroupIndex]} />
          ) : (
            <Message positive>
              <h2>Vielen Dank!</h2>
              <p>
                Ihre Teilnahme an der Auswertung hilft uns, Potenziale innerhalb
                der Feuerwehr zu erkennen und auszubauen.
              </p>
              <p>Sie können den Browser-Tab nun schließen.</p>
            </Message>
          )}
          {/* <CurrentStep accessCode={accessCode} /> */}
          <DefaultActions
            continueDisabled={showEndScreen}
            cantGoBack={activeGroupIndex === 0}
            onNext={() =>
              activeGroupIndex >= groups.length - 1
                ? setShowEndScreen(true)
                : setCurrentQuestionId(nextQuestion.id)
            }
            onPrevious={() =>
              showEndScreen
                ? setShowEndScreen(false)
                : setCurrentQuestionId(
                    questions[
                      questions.findIndex((q) => q.id === currentQuestionId) - 1
                    ].id
                  )
            }
          />
        </>
      )}
    </>
  );
}
